<template>
  <div>
    <v-img
      position
      :aspect-ratio="16 / 3"
      :src="require('@/assets/clients/media/banner/banner.png')"
    >
      <v-container class="fill-height">
        <v-row class="pa-3 gray--text" align="center">
          <div
            :class="$vuetify.breakpoint.smAndDown ? 'title' : 'display-1'"
            class="font-weight-bold"
          >PRODUCTS</div>
        </v-row>
      </v-container>
    </v-img>
    <v-container>
      <v-row>
        <v-col v-for="item in allProductCategories" :key="item.id" cols="6" sm="4" md="3">
          <v-card height="100%" color="green lighten-5" :to="'/products/'+ item.id">
            <v-img height="180" v-if="item.image" :src="item.image"></v-img>
            <v-card-title class="subtitle-2" v-text="item.name"></v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["allProductCategories"])
  },
  methods: {
    ...mapActions(["bind"]),

    fetchCategory() {
      if (this.allProductCategories.length == 0) {
        this.bind({
          collection: "productCategories",
          data: "productCategories"
        });
      }
    }
  },
  created() {
    this.fetchCategory();
  }
};
</script>